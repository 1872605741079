import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import headerLogo from '../../assets/svgs/header-logo.svg';
import menuIcon from '../../assets/svgs/Menu.svg';

const GnbMobile = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.isScrolled ? 'rgba(0, 0, 0, 0.7)' : 'rgb(0, 0, 0)'};
  transition: background-color 0.3s ease;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 16px;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-family: Pretendard;
  z-index: 1000;

  ${props => props.theme.media.mobile} {
    display: flex;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderIcon = styled.img`
  width: 46px;
  position: relative;
  height: 24px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const MenuIcon = styled.img`
  width: 36px;
  position: relative;
  height: 36px;
  cursor: pointer;
`;

const ContactButton = styled(Link)`
  width: 118px;
  border-radius: 4px;
  background-color: #0d44ba;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  box-sizing: border-box;
  text-decoration: none;
  color: inherit;
`;

const ButtonText = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 600;
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  box-sizing: border-box;
  transform: translateY(${props => props.isOpen ? '0' : '-100%'});
  opacity: ${props => props.isOpen ? '1' : '0'};
  transition: all 0.3s ease;
  z-index: 999;
`;

const MenuItem = styled.div`
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  opacity: ${props => props.isActive ? 1 : 0.5};

  &:last-child {
    border-bottom: none;
  }
`;

const MenuLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 24px;
`;

function MobileHeader({ onMenuClick }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleDownload = () => {
    const fileUrl = '/files/펴다회사소개서_v.02.pdf';
    
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "펴다회사소개서_v.02.pdf";
        document.body.appendChild(a);
        a.click();
        
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(error => console.error('Download failed:', error));
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <GnbMobile isScrolled={isScrolled}>
        <Container>
          <Link to="/">
            <HeaderIcon src={headerLogo} alt="펴다 로고" />
          </Link>
          <RightContainer>
            <ContactButton to="/contact">
              <ButtonText>Contact Us</ButtonText>
            </ContactButton>
            <MenuIcon src={menuIcon} alt="메뉴" onClick={toggleMenu} />
          </RightContainer>
        </Container>
      </GnbMobile>
      <MobileMenu isOpen={isMenuOpen}>
        <MenuItem isActive={location.pathname === '/'}>
          <MenuLink to="/" onClick={toggleMenu}>회사소개</MenuLink>
        </MenuItem>
        <MenuItem isActive={location.pathname === '/contact'}>
          <MenuLink to="/contact" onClick={toggleMenu}>도입문의</MenuLink>
        </MenuItem>
        <MenuItem isActive={false}>
          <MenuLink as="div" onClick={() => { handleDownload(); toggleMenu(); }} style={{ cursor: 'pointer' }}>
            서비스 소개서
          </MenuLink>
        </MenuItem>
      </MobileMenu>
    </>
  );
}

export default MobileHeader; 