const breakpoints = {
  mobile: '425px',
  tablet: '768px',
  laptop: '1280px'
};

const media = {
  mobile: `@media (max-width: ${breakpoints.mobile})`,
  tablet: `@media (max-width: ${breakpoints.tablet})`,
  laptop: `@media (max-width: ${breakpoints.laptop})`
};

export const theme = {
  colors: {
    primary: '#3C75F1',
    secondary: '#0D44BA',
    background: '#0F0E10',
    text: {
      primary: '#FFFFFF',
      secondary: '#BCBCBC',
      tertiary: '#858585',
      disabled: '#595959'
    },
    gray: {
      100: '#27252A',
      200: '#8C8C8C',
      300: '#BFBFBF',
      400: '#EEEFF0'
    },
    overlay: 'rgba(0, 0, 0, 0.49)',
    gradient: {
      primary: 'linear-gradient(90deg, #0F0E10 0%, #3C75F1 50%, #0F0E10 100%)',
      blue: 'linear-gradient(93deg, #4D8EE8 0%, #387DDE 100%)',
      dark: 'linear-gradient(0deg, rgba(15, 14, 16, 0) 0%, #0F0E10 100%)'
    }
  },

  typography: {
    heading: {
      h1: {
        fontSize: '56px',
        fontWeight: '600',
        lineHeight: '1.2',
      },
      h2: {
        fontSize: '40px',
        fontWeight: '600',
        lineHeight: '1.3',
      },
      h3: {
        fontSize: '28px',
        fontWeight: '500',
        lineHeight: '42px',
      }
    },
    body: {
      large: {
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: '36px',
      },
      medium: {
        fontSize: '20px',
        fontWeight: '400',
        lineHeight: '30px',
      },
      small: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
      },
      xsmall: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '21px',
      }
    }
  },

  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
    section: {
      desktop: '120px',
      mobile: '60px'
    }
  },

  breakpoints,

  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px',
    xl: '25px',
    full: '9999px'
  },

  shadows: {
    small: '0 2px 4px rgba(0, 0, 0, 0.1)',
    medium: '0 4px 8px rgba(0, 0, 0, 0.1)',
    large: '0 8px 16px rgba(0, 0, 0, 0.1)',
  },

  media
};

export default theme; 