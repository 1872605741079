import styled, { keyframes } from 'styled-components';
import { useEffect, useRef } from 'react';
import { CommonTitle } from '../../styles/commonStyles';

const slideFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Point02Section = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 140px 50px;
  box-sizing: border-box;
  gap: 62px;
  text-align: left;
  font-size: 56px;
  color: #fff;
  font-family: Pretendard;

  ${props => props.theme.media.laptop} {
    padding: 80px 50px;
  }

  ${props => props.theme.media.mobile} {
    padding: 80px 16px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 34px;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;

  ${props => props.theme.media.laptop} {
    gap: 24px;
  }

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`;

const TitleText = styled(CommonTitle)`
  flex-direction: column;
  align-items: flex-start;

  ${props => props.theme.media.laptop} {
    align-items: flex-start;
    flex-direction: column;
    font-size: 40px;
    word-break: keep-all;
  }

  ${props => props.theme.media.mobile} {
    font-size: 32px;
  }
`;

const SubText = styled.div`
  width: 100%;
  position: relative;
  font-size: 24px;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 500;
  color: #858585;
  display: flex;
  align-items: center;

  ${props => props.theme.media.laptop} {
    font-size: 18px;
    font-weight: 300;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
    font-size: 15px;
  }
`;

const PointBox = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 32px 20px;
  font-size: 20px;

  ${props => props.theme.media.laptop} {
    justify-content: center;
    gap: 24px;
  }

  ${props => props.theme.media.mobile} {
    padding: 0;
    gap: 16px;
  }
`;

const PointItem = styled.div`
  width: 414px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 18px;
  opacity: 0;
  
  &.visible {
    animation: ${slideFromRight} 0.8s ease-out forwards;
    animation-delay: ${props => props.delay || '0s'};
  }

  ${props => props.theme.media.laptop} {
    width: calc(50% - 12px);
  }

  ${props => props.theme.media.mobile} {
    width: calc(100% - 8px);
    height: auto;
    align-items: flex-start;
  }
`;

const PointImage = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 240px;
  flex-shrink: 0;
  object-fit: cover;

  ${props => props.theme.media.mobile} {
    height: auto;
  }
`;

const PointTextArea = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 4px;
  gap: 8px;

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`;

const PointTitle = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 600;
`;

const PointSubText = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
`;

function Point02() {
  const itemsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    itemsRef.current.forEach((item) => {
      if (item) {
        observer.observe(item);
      }
    });

    return () => {
      itemsRef.current.forEach((item) => {
        if (item) {
          observer.unobserve(item);
        }
      });
    };
  }, []);

  return (
    <Point02Section>
      <TextContainer>
        <TitleText>
          <p>창조된 로컬힙은 고객사와 대중을 연결하며,</p>
          <p>새로운 경험을 제공합니다.</p>
        </TitleText>
        <SubText>
          펴다는 DiD 광고, 팝업존 구축, OOH 프로모션, 공유 서비스를 통해 고객사와 대중을 연결하며, 유동인구 핵심지역에서 차별화된 경험을 제공하고 하나의 문화현상을 창출합니다.
        </SubText>
      </TextContainer>
      <PointBox>
        <PointItem 
          ref={el => itemsRef.current[0] = el}
          delay="0s"
        >
          <PointImage src="/images/renewal/ImgPoint01.jpg" alt="우양산 공유 서비스" />
          <PointTextArea>
            <PointTitle>우양산 공유 서비스</PointTitle>
            <PointSubText>
              유동인구가 많은 공공장소에 펴다스테이션을 설치하여 불특정 다수에게 브랜드 광고를 제공하며 주목도가 높아 효과적으로 광고/홍보 컨텐츠를 소비자에게 전달할 수 있습니다
            </PointSubText>
          </PointTextArea>
        </PointItem>
        <PointItem 
          ref={el => itemsRef.current[1] = el}
          delay="0.2s"
        >
          <PointImage src="/images/renewal/ImgPoint02.jpg" alt="OOH 프로모션,DiD 광고" />
          <PointTextArea>
            <PointTitle>OOH 프로모션,DiD 광고</PointTitle>
            <PointSubText>
              OOH 광고의 가장 큰 장점 중 하나는 도달범위입니다. Nielsen의 조사에 따르면 OOH 광고는 매주 미국 소비자의 90%에게 도달합니다. 이는 다른 형태의 광고보다 OOH를 통해 더 많은 청중에게 다가갈 수 있다는 것을 의미합니다.
            </PointSubText>
          </PointTextArea>
        </PointItem>
        <PointItem 
          ref={el => itemsRef.current[2] = el}
          delay="0.4s"
        >
          <PointImage src="/images/renewal/ImgPoint03.jpg" alt="팝업존 창출" />
          <PointTextArea>
            <PointTitle>팝업존 창출</PointTitle>
            <PointSubText>
              팝업 스토어는 소비자와 브랜드 간의 직접적인 소통을 강화하는 데 유리합니다. 이를 통해 브랜드에 대한 친밀감을 높이고, 충성도를 증진시킬 수 있습니다
            </PointSubText>
          </PointTextArea>
        </PointItem>
        <PointItem 
          ref={el => itemsRef.current[3] = el}
          delay="0.6s"
        >
          <PointImage src="/images/renewal/ImgPoint04.jpg" alt="광고 우산 제작" />
          <PointTextArea>
            <PointTitle>광고 우산 제작</PointTitle>
            <PointSubText>
              OOH 광고의 가장 큰 장점 중 하나는 도달범위입니다. Nielsen의 조사에 따르면 OOH 광고는 매주 미국 소비자의 90%에게 도달합니다. 이는 다른 형태의 광고보다 OOH를 통해 더 많은 청중에게 다가갈 수 있다는 것을 의미합니다.
            </PointSubText>
          </PointTextArea>
        </PointItem>
      </PointBox>
    </Point02Section>
  );
}

export default Point02; 