import styled, { keyframes } from 'styled-components';
import { useEffect, useRef } from 'react';
import { fadeInAnimation } from '../../styles/animations';
import { CommonTitle } from '../../styles/commonStyles';

const slideFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Point01Section = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 140px 50px;
  box-sizing: border-box;
  gap: 62px;
  text-align: left;
  font-size: 56px;
  color: #fff;
  font-family: Pretendard;
  ${fadeInAnimation}

  ${props => props.theme.media.laptop} {
    padding: 80px 50px;
  }

  ${props => props.theme.media.mobile} {
    padding: 80px 16px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 34px;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;

  ${props => props.theme.media.laptop} {
    gap: 24px;
  }

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`;

const TitleText = styled(CommonTitle)`
  ${props => props.theme.media.laptop} {
    align-items: flex-start;
    flex-direction: column;
    font-size: 40px;
    word-break: keep-all;
  }

  ${props => props.theme.media.mobile} {
    font-size: 32px;
    word-break: keep-all;
  }
`;

const SubText = styled.div`
  width: 729px;
  position: relative;
  font-size: 24px;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 500;
  color: #858585;
  display: flex;
  align-items: center;

  ${props => props.theme.media.laptop} {
    width: 100%;
    font-size: 18px;
    font-weight: 300;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
    font-size: 15px;
  }
`;

const SubTextContent = styled.span`
  width: 100%;

  p {
    margin: 0;
  }

  p:last-child {
    white-space: pre-wrap;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  opacity: 0;
  
  &.visible {
    animation: ${slideFromRight} 1s ease-out forwards;
  }

  ${props => props.theme.media.mobile} {
    padding: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 768px;
  position: relative;
  height: auto;
  object-fit: cover;

  ${props => props.theme.media.mobile} {
    width: calc(100% - 8px);
    height: auto;
  }
`;

function Point01() {
  const imgContainerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (imgContainerRef.current) {
      observer.observe(imgContainerRef.current);
    }

    return () => {
      if (imgContainerRef.current) {
        observer.unobserve(imgContainerRef.current);
      }
    };
  }, []);

  return (
    <Point01Section>
      <TextContainer>
        <TitleText>
          <p>새로운 문화아이콘,
          문화현상을 창조하다</p>
        </TitleText>
        <SubText>
          <SubTextContent>
            <p>일상 속에 자연스럽게 녹아드는 로컬힙 솔루션 '펴다'</p>
            <p>핵심지역에서 고객사와 대중을 연결하여 하나의 문화현상을 창조합니다</p>
          </SubTextContent>
        </SubText>
      </TextContainer>
      <ImgContainer ref={imgContainerRef}>
        <Image src="/images/renewal/Point01.png" alt="Point01" />
      </ImgContainer>
    </Point01Section>
  );
}

export default Point01; 