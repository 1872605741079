import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    min-width: 360px;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    line-height: 1.5;
    background-color: rgb(0, 0, 0);
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyle; 