import styled from 'styled-components';
import { CommonTitle } from '../../styles/commonStyles';
import { Link } from 'react-router-dom';

const Point06Section = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 80px 50px;
  box-sizing: border-box;
  gap: 62px;
  text-align: left;
  font-size: 56px;
  color: #fff;
  font-family: Pretendard;

  ${props => props.theme.media.laptop} {
    padding: 80px 50px;
    gap: 32px;
    font-size: 40px;
  }

  ${props => props.theme.media.mobile} {
    padding: 80px 16px;
  }
`;

const ContentArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 34px;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;

  ${props => props.theme.media.laptop} {
    gap: 24px;
  }
`;

const TopContent = styled(CommonTitle)`
  ${props => props.theme.media.laptop} {
    font-size: 40px;
    word-break: keep-all;
  }

  ${props => props.theme.media.mobile} {
    font-size: 32px;
  }
`;

const SubContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 24px;
  color: #858585;

  ${props => props.theme.media.laptop} {
    font-size: 18px;
  }
`;

const SubContent = styled.div`
  width: 844px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 400;
  display: flex;
  align-items: center;

  ${props => props.theme.media.laptop} {
    width: 100%;
    font-size: 18px;
    font-weight: 300;
  }

  ${props => props.theme.media.mobile} {
    font-size: 15px;
  }
`;

const ButtonArea = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  text-align: center;
  font-size: 20px;

  ${props => props.theme.media.laptop} {
    flex-direction: column;
    gap: 16px;
  }
`;

const Button = styled.a`
  width: 207px;
  border-radius: 152px;
  border: 1px solid #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 22px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;

  ${props => props.theme.media.laptop} {
    width: 100%;
    max-width: 400px;
    height: 50px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const ButtonText = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 24px;
  font-weight: 500;
`;

function Point06() {
  const handleDownload = () => {
    const fileUrl = '/files/펴다회사소개서_v.02.pdf';
    
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "펴다회사소개서_v.02.pdf";
        document.body.appendChild(a);
        a.click();
        
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(error => console.error('Download failed:', error));
  };

  return (
    <Point06Section>
      <ContentArea>
        <TopContent>이런 기업은 주저말고 연락하세요.</TopContent>
        <SubContentArea>
          <SubContent>마케팅을 넘어 기업 가치 상승의 솔루션을 제시받고 싶은 기업</SubContent>
          <SubContent>생활권 핵심지역에서의 효과적인 타겟 광고를 하고 싶은 기업</SubContent>
          <SubContent>ESG 또는 사내 복지 차원의 우산대여 서비스 도입 니즈가 있는 기업</SubContent>
        </SubContentArea>
      </ContentArea>
      <ButtonArea>
        <Button as="div" onClick={handleDownload}>
          <ButtonText>서비스 소개서</ButtonText>
        </Button>
        <Button as={Link} to="/contact">
          <ButtonText>설치 / 광고 문의</ButtonText>
        </Button>
      </ButtonArea>
    </Point06Section>
  );
}

export default Point06; 