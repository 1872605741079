import styled, { keyframes } from 'styled-components';
import { useEffect, useRef } from 'react';
import logoIcon from '../../assets/svgs/Logo-eng.svg';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ServiceCopySection = styled.div`
  width: 100%;
  position: relative;
  height: 870px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 160px 50px 0px;
  box-sizing: border-box;
  text-align: center;
  font-size: 40px;
  color: #fff;
  font-family: Pretendard;

  ${props => props.theme.media.laptop} {
    width: 100%;
    position: relative;
    height: 600px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 120px 50px 0px;
    box-sizing: border-box;
    text-align: center;
    font-size: 32px;
    color: rgb(255, 255, 255);
    font-family: Pretendard;
  }

  ${props => props.theme.media.mobile} {
    padding: 60px 16px 0px;
  }
`;

const BackgroundImage = styled.img`
  width: 1322px;
  position: absolute;
  top: 455px;
  left: 50%;
  transform: translateX(-50%);
  height: 657px;
  object-fit: cover;
  z-index: 0;
  margin: 0;

  ${props => props.theme.media.laptop} {
    width: 760px;
    position: absolute;
    top: 260px;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    object-fit: contain;
    z-index: 0;
    margin: 0;
  }
`;

const Container = styled.div`
  width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 53px;
  z-index: 1;

  ${props => props.theme.media.laptop} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    z-index: 1;
  }
`;

const Logo = styled.img`
  width: 406.1px;
  position: relative;
  height: 80px;
  opacity: 0;
  
  &.visible {
    animation: ${slideUp} 0.8s ease-out forwards;
    animation-delay: 0s;
  }

  ${props => props.theme.media.laptop} {
    width: 320px;
    height: auto;
  }
`;

const Divider = styled.div`
  width: 630px;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(90deg, #0f0e10, #3c75f1 50%, #0f0e10);
  height: 3px;
  opacity: 0;
  
  &.visible {
    animation: ${slideUp} 0.8s ease-out forwards;
    animation-delay: 0.2s;
  }
`;

const TopContent = styled.div`
  align-self: stretch;
  position: relative;
  font-weight: 600;
  opacity: 0;
  
  &.visible {
    animation: ${slideUp} 0.8s ease-out forwards;
    animation-delay: 0.4s;
  }

  p {
    margin: 0;
  }

  p:last-child {
    color: #3c75f1;
    span:last-child {
      color: #fff;
    }
  }

  ${props => props.theme.media.laptop} {
    font-size: 32px;
  }

  ${props => props.theme.media.mobile} {
    font-size: 32px;
  }
`;

const SubContent = styled.div`
  position: relative;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 150%;
  color: #bcbcbc;
  opacity: 0;
  
  &.visible {
    animation: ${slideUp} 0.8s ease-out forwards;
    animation-delay: 0.6s;
  }

  p {
    margin: 0;
  }

  ${props => props.theme.media.laptop} {
    font-size: 16px;
  }
`;

function ServiceCopy() {
  const logoRef = useRef(null);
  const dividerRef = useRef(null);
  const topContentRef = useRef(null);
  const subContentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    [logoRef, dividerRef, topContentRef, subContentRef].forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      [logoRef, dividerRef, topContentRef, subContentRef].forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  return (
    <ServiceCopySection>
      <BackgroundImage src="/images/renewal/image-back.png" alt="" />
      <Container>
        <Logo ref={logoRef} src={logoIcon} alt="펴다 로고" />
        <Divider ref={dividerRef} />
        <TopContent ref={topContentRef}>
          <p>펴다는 고객사의 브랜드 목표를 실현시키는</p>
          <p>
            <span>크리에이티브 솔루션 파트너</span>
            <span> 입니다.</span>
          </p>
        </TopContent>
        <SubContent ref={subContentRef}>
          <p>펴다는 자체 개발한 우양산 공유 서비스와 Ad-tech 기반 DiD 광고를 결합한 '펴다 스테이션'을 통해,</p>
          <p>ESG 캠페인과 온·오프라인 통합 마케팅 커뮤니케이션, 그리고 차별화된 브랜드 경험을 제공합니다.</p>
        </SubContent>
      </Container>
    </ServiceCopySection>
  );
}

export default ServiceCopy; 