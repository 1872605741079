import styled, { keyframes } from 'styled-components';
import { useEffect, useRef } from 'react';

const scaleDown = keyframes`
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Point03Section = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 140px 50px;
  box-sizing: border-box;
  gap: 62px;
  text-align: left;
  font-size: 56px;
  color: #fff;
  font-family: Pretendard;

  ${props => props.theme.media.laptop} {
    padding: 80px 50px;
  }

  ${props => props.theme.media.mobile} {
    padding: 80px 16px;
  }
`;

const Container = styled.div`
  width: 1280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  z-index: 1;
  background-image: url('/images/renewal/Point03.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 120px 0;
  border-radius: 32px;
  opacity: 0;
  
  &.visible {
    animation: ${scaleDown} 1s ease-out forwards,
              ${fadeIn} 1s ease-out forwards 0.4s;
  }

  ${props => props.theme.media.laptop} {
    width: 90%;
    gap: 12px;
    font-size: 40px;
  }

  ${props => props.theme.media.mobile} {
    width: calc(100% - 8px);
    height: 200px;
  }
`;

const TopContent = styled.div`
  align-self: stretch;
  position: relative;
  font-weight: 600;
  text-align: center;
`;

const Text = styled.div`
  align-self: stretch;
  text-align: center;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 150%;
  color: white;

  p {
    margin: 0;
  }

  ${props => props.theme.media.laptop} {
    font-size: 16px;
  }

  ${props => props.theme.media.mobile} {
    font-size: 15px;
  }
`;

function Point03() {
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <Point03Section>
      <Container ref={containerRef}>
        <TopContent>도시에 색을 입히다</TopContent>
        <Text>
          <p>펴다는 '힙한 거리의 새로운 아이콘'으로 자리매김하고</p>
          <p>지역과의 연결성을 강화하며 고객에게 새로운 브랜드 경험을 제공합니다.</p>
        </Text>
      </Container>
    </Point03Section>
  );
}

export default Point03; 