import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: ${props => props.theme.colors.gray[100]};
  padding: 56px 60px;

  ${props => props.theme.media.mobile} {
    padding: 40px 16px;
  }
`;

const FooterContent = styled.div`
  width: 1320px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  word-break: keep-all;

  ${props => props.theme.media.laptop} {
    width: 100%;
  }

  ${props => props.theme.media.mobile} {
    width: 100%;
    padding: 0;
  }
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const CompanyInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  object-fit: contain;
`;

const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CompanyName = styled.div`
  color: ${props => props.theme.colors.text.disabled};
  font-size: ${props => props.theme.typography.body.small.fontSize};
  font-weight: 700;
  word-break: keep-all;
`;

const InfoText = styled.div`
  color: ${props => props.theme.colors.gray[200]};
  font-size: ${props => props.theme.typography.body.xsmall.fontSize};
  line-height: 20px;
  word-break: keep-all;
`;

const Copyright = styled.div`
  color: ${props => props.theme.colors.gray[300]};
  font-size: ${props => props.theme.typography.body.xsmall.fontSize};
  font-weight: 500;
  text-align: center;
  word-break: keep-all;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <TopSection>
          <CompanyInfo>
            <Logo src="/images/renewal/footerlogo.png" alt="펴다 로고" />
            <InfoList>
              <CompanyName>주식회사 펴다 ㅣ대표 : 김형석</CompanyName>
              <div>
                <InfoText>본사 : 서울시 종로구 광화문 스타트업 빌리지</InfoText>
                <InfoText>사업자 등록 번호 : 710-87-02407</InfoText>
                <InfoText>지사 : 경기도 안산시 청년창업사관학교(안산)</InfoText>
                <InfoText>이메일 : support@pyeoda.co.kr</InfoText>
              </div>
            </InfoList>
          </CompanyInfo>
        </TopSection>
        <Copyright>Copyright©2022 펴다All rights reserved</Copyright>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
