import styled, { keyframes } from 'styled-components';
import { useEffect, useRef } from 'react';
import { CommonTitle } from '../../styles/commonStyles';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Point04Section = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 80px 50px;
  box-sizing: border-box;
  gap: 62px;
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-family: Pretendard;

  ${props => props.theme.media.laptop} {
    padding: 0px 50px;
    gap: 32px;
    font-size: 20px;
  }

  ${props => props.theme.media.mobile} {
    padding: 0px 16px;
  }
`;

const EngTitle = styled.div`
  width: 100%;
  max-width: 729px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 500;
  color: #3c75f1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled(CommonTitle)`
  ${props => props.theme.media.laptop} {
    font-size: 40px;
    margin-bottom: 24px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  text-align: left;
  font-size: 28px;

  ${props => props.theme.media.laptop} {
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }
`;

const Card = styled.div`
  width: 305px;
  position: relative;
  height: 420px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: #fff;
  opacity: 0;
  
  &.visible {
    animation: ${slideUp} 0.8s ease-out forwards;
    animation-delay: ${props => props.delay || '0s'};
  }

  ${props => props.theme.media.laptop} {
    width: calc(50% - 8px);
    height: 360px;
  }

  ${props => props.theme.media.mobile} {
    width: calc(100% - 8px);
    height: 200px;
  }
`;

const CardImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Card02 = styled(Card)`
  background-image: url('/images/renewal/Point04-Card02.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-color: transparent;
`;

const TopContent = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 500;
  color: #fff;

  ${props => props.theme.media.laptop} {
    font-size: 24px;
  }
`;

function Point04() {
  const cardsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    cardsRef.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardsRef.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  return (
    <Point04Section>
      <EngTitle>
        Pyeoda Business
      </EngTitle>
      <TitleText>
        <span>
          <p>브랜드 목표의 실현,</p>
          <p>펴다가 함께합니다.</p>
        </span>
      </TitleText>
      <Container>
        <Card 
          ref={el => cardsRef.current[0] = el} 
          delay="0s"
        >
          <CardImage src="/images/renewal/Point04-Card01.jpg" alt="우양산 공유" />
          <TopContent>우양산 공유</TopContent>
        </Card>
        <Card02 
          ref={el => cardsRef.current[1] = el}
          delay="0.2s"
        >
          <TopContent>펴다 머신러닝 엔진</TopContent>
        </Card02>
        <Card 
          ref={el => cardsRef.current[2] = el}
          delay="0.4s"
        >
          <CardImage src="/images/renewal/Point04-Card03.jpg" alt="DiD 광고" />
          <TopContent>DiD 광고</TopContent>
        </Card>
        <Card 
          ref={el => cardsRef.current[3] = el}
          delay="0.6s"
        >
          <CardImage src="/images/renewal/Point04-Card04.jpg" alt="IT 서비스 개발" />
          <TopContent>IT 서비스 개발</TopContent>
        </Card>
      </Container>
    </Point04Section>
  );
}

export default Point04; 