import styled from 'styled-components';

export const CommonTitle = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  font-size: 56px;
  font-weight: 500;
  display: flex;
  align-items: center;

  span {
    width: 100%;
  }

  p {
    margin: 0;
  }
`; 