import styled, { keyframes } from 'styled-components';
import { useEffect, useRef } from 'react';
import { fadeInAnimation } from '../../styles/animations';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DoingSection = styled.div`
  width: 100%;
  position: relative;
  background-color: #0f0e10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 120px 0px 40px;
  box-sizing: border-box;
  text-align: left;
  font-size: 20px;
  color: #fff;
  font-family: Pretendard;
  ${fadeInAnimation}
`;

const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  ${props => props.theme.media.laptop} {
    align-items: stretch;
  }

  ${props => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

const DoingItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 50px 32px;
  gap: 44px;
  border-right: ${props => props.hasBorder ? '1px solid rgba(255, 255, 255, 0.5)' : 'none'};
  opacity: 0;
  
  &.visible {
    animation: ${slideUp} 0.8s ease-out forwards;
    animation-delay: ${props => props.delay || '0s'};
  }

  ${props => props.theme.media.laptop} {
    padding: 40px 24px;
  }

  ${props => props.theme.media.mobile} {
    border-right: none;
    border-bottom: ${props => props.hasBorder ? '1px solid rgba(255, 255, 255, 0.5)' : 'none'};
    padding: 32px 16px;
  }
`;

const TitleText = styled.div`
  align-self: stretch;
  position: relative;
  font-weight: 500;
  word-break: keep-all;

  p {
    margin: 0;
  }

  ${props => props.theme.media.laptop} {
    font-size: 18px;
  }
`;

const Number = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 150%;
`;

const TitleKor = styled.div`
  align-self: stretch;
  position: relative;
  text-decoration: underline;
  letter-spacing: -0.02em;
  line-height: 150%;
`;

const SubText = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
  opacity: 0.9;

  p {
    margin: 0;
  }

  ${props => props.theme.media.laptop} {
    font-size: 14px;
  }

  ${props => props.theme.media.mobile} {
    font-size: 15px;
  }
`;

function Doing() {
  const itemsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    itemsRef.current.forEach((item) => {
      if (item) {
        observer.observe(item);
      }
    });

    return () => {
      itemsRef.current.forEach((item) => {
        if (item) {
          observer.unobserve(item);
        }
      });
    };
  }, []);

  return (
    <DoingSection>
      <Container>
        <DoingItem 
          hasBorder 
          ref={el => itemsRef.current[0] = el}
          delay="0s"
        >
          <TitleText>
            <Number>(01)</Number>
            <Number>WHAT WE DO</Number>
            <TitleKor>우리는 무엇을 하는가</TitleKor>
          </TitleText>
          <SubText>
            <p>펴다는 자체 개발한 우양산 공유 서비스와 Ad-tech 기반 DiD 광고를 결합한'크리에이티브 솔루션'을 제공합니다.</p>
            <p>우리가 보유한 뉴미디어 매체와, IT서비스를 활용해 창의적인 콘텐츠로 브랜드를 확산시킵니다.</p>
          </SubText>
        </DoingItem>

        <DoingItem 
          hasBorder 
          ref={el => itemsRef.current[1] = el}
          delay="0.2s"
        >
          <TitleText>
            <Number>(02)</Number>
            <Number>How?</Number>
            <TitleKor>어떻게 만들어 가는가</TitleKor>
          </TitleText>
          <SubText>
            펴다의 독보적인 기술적 역량을 바탕으로 고객사와 대중을 연결하며, DiD광고, 팝업존 구축, OOH 프로모션, 공유서비스를 통해 유동인구 핵심 지역에서 차별화된 경험을 제공합니다
          </SubText>
        </DoingItem>

        <DoingItem 
          ref={el => itemsRef.current[2] = el}
          delay="0.4s"
        >
          <TitleText>
            <Number>(03)</Number>
            <Number>Why Pyeoda</Number>
            <TitleKor>우리는 왜 존재하는가</TitleKor>
          </TitleText>
          <SubText>
            펴다는 기업들이 브랜드 가치를 높이는데 어려움을 겪고 있다는 점을 알고있습니다. 이러한 고민을 해결하기 위해 차별화된 경험을 제공하여 유의미한 브랜드 발굴과 시장 경쟁력 강화를 지원합니다
          </SubText>
        </DoingItem>
      </Container>
    </DoingSection>
  );
}

export default Doing; 