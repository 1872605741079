import { keyframes, css } from 'styled-components';

export const fadeInKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeInUpKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const fadeInAnimation = css`
  animation: ${fadeInKeyframes} 1s ease-in-out;
`;

export const fadeInUpAnimation = (delay = 0) => css`
  opacity: 0;
  animation: ${fadeInUpKeyframes} 1s ease-out ${delay}s forwards;
`;

// 지연 시간을 포함한 페이드인
export const fadeInWithDelay = (delay = 0) => css`
  opacity: 0;
  animation: ${fadeInKeyframes} 1s ease-in-out ${delay}s forwards;
`; 