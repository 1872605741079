import styled from 'styled-components';

const VideoSection = styled.div`
  width: 100%;
  height: 56.25vw;
  position: relative;
  overflow: hidden;
  background-color: #000;

  ${props => props.theme.media.mobile} {
    margin-top: 60px;
  }
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(15, 14, 16, 0) 0%, #0F0E10 100%);
`;

function MainVideo() {
  return (
    <VideoSection>
      <VideoWrapper>
        <iframe
          width="1280"
          height="720"
          src="https://www.youtube.com/embed/AgPK2InQvUA?autoplay=1&mute=1&loop=1&playlist=AgPK2InQvUA&controls=0"
          title="[펴다] 우산 사지말고 빌리세요"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </VideoWrapper>
      <Overlay />
    </VideoSection>
  );
}

export default MainVideo; 